/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {sessionvrDetails} from '../../../../api/sessionVRApi'
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('it');

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: string;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewDetailsSessionVR = ({ show, handleClose, recordId }: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>("Discenti_partecipanti");

  const [learnersParticipateDetails, setLearnersParticipateDetails] = useState<any>(null);
  const [simulationsDone, setSimulationsDone] = useState<any[]>([]);

  useEffect(() => {
    if (show) {
      fetchRecordDetails(recordId);
      setSelectedTab("Discenti_partecipanti");
    } else {
      setSelectedTab("Discenti_partecipanti");
    }
  }, [show, recordId]);


  const fetchRecordDetails = async (id: string) => {
    try {
      const response = await sessionvrDetails(id);
      console.log("dettagli", response)
      
      setLearnersParticipateDetails(response.learnersParticipateDetails);
      setSimulationsDone(response.simulationsDone);

    } catch (error) {
      console.error(error);
    }
  };

  return createPortal(
    <Modal
      id="kt_modal_view_details"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Dettagli Sessione VR</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        <ul className="nav nav-stretch nav-line-tabs fw-bold fs-6 p-0 p-lg-10 flex-nowrap flex-grow-1" role="tablist">
          <li className="nav-item mx-lg-1" role="presentation">
            <a className="nav-link py-3 py-lg-6 text-active-primary" data-bs-toggle="tab" data-bs-target="#kt_app_header_menu_pages_pages" 
               role="tab" onClick={() => setSelectedTab("Discenti_partecipanti")}>Partecipanti</a>
          </li>
          <li className="nav-item mx-lg-1" role="presentation">
            <a className="nav-link py-3 py-lg-6 text-active-primary" data-bs-toggle="tab" data-bs-target="#kt_app_header_menu_pages_pages" 
               role="tab" onClick={() => setSelectedTab("Simulazioni_usate")}>Simulazioni usate</a>
          </li>
        </ul>

        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        {learnersParticipateDetails && (
            selectedTab === "Discenti_partecipanti" ? (
        <div className="row"> {/* Contenitore esterno della griglia */}
          <div className="col-md-6"> {/* Contenitore della colonna sinistra */}
            <div className='card-body p-9'>
              {/* Blocco 1: Discenti partecipanti */}
                <div className='row mb-7'>
                    {/*<label className='col-lg-4 fw-bold text-muted'>Discenti partecipanti</label>*/}
                    <div className='col-lg-8'>
                        {learnersParticipateDetails.length === 0 ? (
                            <span className='fw-bolder fs-6 text-dark'>Nessuno discente partecipante</span>
                        ) : (
                            <ul>
                              {learnersParticipateDetails.map((learner:any, index:any) => (
                                <li key={index} className='fw-bolder fs-6 text-dark'>{learner.Nome} {learner.Cognome}</li>
                              ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
          </div>
        </div>
        ) : (
        <div className="row">
          <div className="col-md-6"> {/* Contenitore della colonna sinistra */}
            <div className='card-body p-9'>
              {/* Blocco 2: Simulazioni scelte */}
                <div className='row mb-7'>
                    {/*<label className='col-lg-4 fw-bold text-muted'></label>*/}
                    <div className='col-lg-8'>
                        {simulationsDone.length === 0 ? (
                            <span className='fw-bolder fs-6 text-dark'>Nessun simulazione utilizzata</span>
                        ) : (
                            <ul>
                            {simulationsDone.map((simulation, index) => (
                              <li key={index} className='fw-bolder fs-6 text-dark'>{simulation.Titolo}</li>
                            ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
          </div>
        </div>
        )
        )}
          </div>
        </div>
      </Modal>,
    modalsRoot
  );
};

export { ViewDetailsSessionVR };