/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'

type UsersListPaginationProps = {
  totalItems: number
  itemsPerPage: number
  currentPage: number
  onPageChange: (newPage: number) => void
}

const UsersListPagination: React.FC<UsersListPaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) {
      return
    }
    onPageChange(newPage)
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: currentPage === 1,
              })}
            >
              <a
                className='page-link'
                onClick={() => handlePageChange(currentPage - 1)}
                style={{ cursor: 'pointer' }}
              >
                Precedente
              </a>
            </li>
            {Array.from(Array(totalPages).keys()).map((_, index) => (
              <li
                key={index + 1}
                className={clsx('page-item', {
                  active: currentPage === index + 1,
                })}
              >
                <a
                  className='page-link'
                  onClick={() => handlePageChange(index + 1)}
                  style={{ cursor: 'pointer' }}
                >
                  {index + 1}
                </a>
              </li>
              ))}
            <li
              className={clsx('page-item', {
                disabled: currentPage === totalPages,
              })}
            >
              <a
                className='page-link'
                onClick={() => handlePageChange(currentPage + 1)}
                style={{ cursor: 'pointer' }}
              >
                Successivo
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { UsersListPagination }