/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useRoles } from '../../../../../hooks/userRoleContext'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { roles } = useRoles();
  //console.log(roles);

  // Verifica se l'utente ha il ruolo 'admin'
  const isAdmin = roles ? roles.includes('admin') : false;

  //console.log(roles);

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/*

      <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />

      */}

      {/*Ordini noleggio/vendita*/}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Sessioni VR</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/genera-sessione-vr'
        title='Genera sessione VR'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen055.svg' //questo o il gen035
      >
      </SidebarMenuItem>

      <SidebarMenuItem
        to='/storico-sessioni-vr'
        title='Storico sessioni VR'
        icon='/media/icons/duotune/abstract/abs034.svg'
        fontIcon='bi-person'
      >
      </SidebarMenuItem>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Risultati</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/storico-risultati'
        title='Storico risultati'
        icon='/media/icons/duotune/abstract/abs034.svg'
        fontIcon='bi-person'
      >
      </SidebarMenuItem>

      {/*Gestione visori*/}

      {/*<div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Gestione visori</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/inserisci-visore'
        title='Inserisci visore'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen055.svg' //questo o il gen035
      >
      </SidebarMenuItem>

      <SidebarMenuItem
        to='/libro-mastro'
        title='Libro mastro'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen017.svg' //questo o il gen035
      >
      </SidebarMenuItem>
	  
	    <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Altri inserimenti</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/inserisci-piano_abbonamento'
        title='Inserisci piano abbonamento'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen055.svg' //questo o il gen035
      >
      </SidebarMenuItem>
	  
	    <SidebarMenuItem
        to='/inserisci-intermediario'
        title='Inserisci intermediario'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen055.svg' //questo o il gen035
      >
      </SidebarMenuItem>*/}

      {/*<SidebarMenuItem
        to='/lista-clienti'
        title='Lista clienti'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/abstract/abs015.svg'
      >
      </SidebarMenuItem>*/}

      {/*<SidebarMenuItem
        to='/statistiche'
        title='Statistiche'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/graphs/gra001.svg'
      >
      </SidebarMenuItem>*/}

      {/*<SidebarMenuItem
        to='/error'
        title='Magazzino'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen017.svg'
      >
      </SidebarMenuItem>*/}

      {/*

      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>

      */}

      {/*Ordini prova gratuita*/}

      {/*

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Ordini prova gratuita</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/crafted/pages'
        title='Inserisci ordine'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen055.svg'
      >
      </SidebarMenuItem>

      <SidebarMenuItem
        to='/crafted/accounts'
        title='Stato ordini'
        icon='/media/icons/duotune/abstract/abs034.svg'
        fontIcon='bi-person'
      >
      </SidebarMenuItem>


      <SidebarMenuItem
        to='/error'
        title='Controllo disponibilità'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen017.svg'
      >
      </SidebarMenuItem>

      */}

      {/*Impostazioni*/}

      {isAdmin && (
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Impostazioni</span>
        </div>
      </div>
      )}

      {isAdmin && (
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      )}

      {isAdmin && (
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      )}

      {isAdmin && (
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
      )}
    </>
  )
}

export {SidebarMenuMain}
