import * as Yup from 'yup'
import {validationConfig} from '../../../validationConfig';

export interface ICreateSessionVR {
    Nome_formatore: string
    Cognome_formatore: string
    Data_inizio: Date | null
    COD_User_SVR: number | null
}

export const generateSessionVRSchemas = Yup.object().shape({
  Nome_formatore: validationConfig.Nome_formatore.required
    ? Yup.string().required('Nome formatore obbligatorio').label('Nome_formatore')
    : Yup.string().label('Nome_formatore'),
  Cognome_formatore: validationConfig.Cognome_formatore.required
    ? Yup.string().required('Cognome formatore obbligatorio').label('Cognome_formatore')
    : Yup.string().label('Cognome_formatore'),
  Data_inizio: validationConfig.Data_inizio.required
    ? Yup.date().nullable().required('Data inizio obbligatoria').label('Data_inizio')
    : Yup.string().nullable().label('Data_inizio'),
});

export const inits: ICreateSessionVR = {
    Nome_formatore: '',
    Cognome_formatore: '',
    Data_inizio: null,
    COD_User_SVR: null,
}
