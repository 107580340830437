import axios from '../config/axiosConfig';

/* Elenco delle simulazioni */
export const listSimulation = async () => {
  try {
    const response = await axios.post('/api/simulation/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching simulations: ', error);
    throw error;
  }
};
