export interface ValidationRule {
    required: boolean;
    type: string;
  }
  
  export const validationConfig: { [key: string]: ValidationRule } = {
    // Sessione VR
    Nome_formatore: {
      required: true,
      type: 'string',
    },
    Cognome_formatore: {
      required: true,
      type: 'string',
    },
    Data_inizio: {
      required: true,
      type: 'date',
    },
  };