/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useCookies} from 'react-cookie'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import {useRoles} from '../../../../hooks/userRoleContext'


const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato email errato')
    .min(3, 'Minimo 3 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required('Email obbligatoria'),
  password: Yup.string()
    .min(8, 'Minimo 8 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required('Password obbligatoria'),
})

export function Login() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['api_token'])
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  //const [credentials, setCredentials] = useState({ email: 'prova3@gmail.com', password: 'provasasa3' })

  const { login } = useRoles();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        /*
        {
          /*withCredentials: true,
          headers: {
            'X-SRF-Token': values.csrfToken,    è un tipo di protezione avanzato che ora non mi sento di implementare poichè i moduli storici atti a questo sono stati tutti deprecati.
          },                                    Detto questo, se in futuro avessi tempo e voglia di implementarlo, userei probabilmente il modulo "csrf-csrf" , anche se un po' artigianale
        });*/
        await login(values.email, values.password);
        navigate('/dashboard');
      } catch (error) {
        console.error(error);
        //saveAuth(undefined);
        setStatus('Le credenziali del login sono errate');
      }
      setSubmitting(false);
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Login</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Effettua l'accesso al portale clienti</div>
      </div>
      {/* begin::Heading */}

      {/* end::Login options */}

      {/*{formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )}*/}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          {/*Password dimenticata?*/}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Accedi</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Attendere prego...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/*<div className='text-gray-500 text-center fw-semibold fs-6'>
        Non ancora un membro?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Registrazione
        </Link>
       </div>*/}
    </form>
  )
}
