/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {StoricoSessioniVRWidget} from '../../../_metronic/partials/widgets/customs/StoricoSessioniVRWidget'

export function Storico_sessioniVR() {
  return (
    <>	
      <div className='col-xl-12'>
        <StoricoSessioniVRWidget className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </>
  )
}
