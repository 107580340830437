/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useMemo, useState, useEffect} from 'react'
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import {KTSVG, useDebounce} from '../../../helpers'
import {listLevelResult} from '../../../../api/levelResultApi'
import {listSimulation} from '../../../../api/simulationApi'
import Select from 'react-select'
import {ViewDetailsLevelResult} from '../../modals/view-details-level-result/ViewDetailsLevelResult'
import {DeleteLevelResult} from '../../modals/delete-level-result/DeleteLevelResult'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {UsersListPagination} from '../../../../app/modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import { useAuth } from '../../../../app/modules/auth/core/Auth'

import './StoricoRisultatiWidget.css';

import dayjs from 'dayjs';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('it');

interface MyData {
  Nome_discente: string
  Cognome_discente: string
  ID_Risultato_livello: number
  Livello: string
  Punteggio: string
  Simulazione: string
  Data_risultato: string
  ID_Sessione_VR: string
}

type SortConfig = {
  key: keyof MyData;
  direction: 'asc' | 'desc';
};

type Props = {
  className: string
}

interface SelectedOption {
  value: string;
  label: string;
}

const StoricoRisultatiWidget: React.FC<Props> = ({className}) => {
  const {currentUser, logout} = useAuth()
  const user = Array.isArray(currentUser) && currentUser.length > 0 ? currentUser[0] : currentUser;

  const [data, setData] = useState<MyData[]>([]);
  const [filteredData, setFilteredData] = useState<MyData[]>([]); // New state for filtered data
  const [searchTerm, setSearchTerm] = useState<string>(''); // New state for search term
  const debouncedSearchTerm = useDebounce(searchTerm, 150); // Debounce search term
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'ID_Risultato_livello', direction: 'desc' });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Numero di elementi per pagina

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [typeSimulation, setTypeSimulation] = useState<string>('');

  const [simulationList, setSimulationList] = useState<any[]>([]);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      // Verifica se la chiave è "Numero" e converti i valori in numeri prima di confrontarli
      if (sortConfig.key === 'ID_Risultato_livello') {
        const aValue = Number(a.ID_Risultato_livello) || 0; // Usa 0 se il Numero non può essere convertito in un numero
        const bValue = Number(b.ID_Risultato_livello) || 0; // Usa 0 se il Numero non può essere convertito in un numero
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } 
      // Per tutti gli altri campi, usa il confronto di stringhe come prima
      else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });
  }, [filteredData, sortConfig]);

  const handleRowClick = (action: string, recordId: number) => {
    setSelectedRecordId(recordId);
    if(action === 'view-details') {
      setShowModal(true);
    } else if(action === 'delete-levelresult') {
      setShowDeleteModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeleteCloseModal = () => {
    setShowDeleteModal(false);
  };


  const fetchData = useCallback(async () => {
    try {
      let response = await listLevelResult(user.COD_User);
      console.log("risultati", response)
      //Casto le date da formato americano a italiano
      for (let i = 0; i < response.length; i++) {
        let Data_risultato_US = dayjs(response[i].Data_risultato);
        response[i].Data_risultato = Data_risultato_US.format('LL');
      }

      // Calcola il conteggio per ogni stato
      /*const counts: { [key: string]: number } = {};
      response.forEach((goggle: MyData) => {
        counts[goggle.Simulazione] = (counts[goggle.Simulazione] || 0) + 1;
      });*/

      // Filtro in base a `typeSimulation`, se è stato selezionato
      if (typeSimulation && typeSimulation !== '') {
        response = response.filter((item: MyData) => String(item.Simulazione) === typeSimulation);
      }   

      // Imposta i dati
      setData(response);
    } catch (error) {
      console.error(error);
    }
  }, [typeSimulation]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // useEffect per gestire la barra di ricerca
  useEffect(() => {
    if (debouncedSearchTerm) {
      const lowerCasedSearchTerm = debouncedSearchTerm.toLowerCase();
      const filtered = data.filter(goggle => {
        return Object.values(goggle).some(val => {
          if (val !== null && typeof val === 'string') {
            return val.toLowerCase().includes(lowerCasedSearchTerm);
          }
          return false;
        });
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [debouncedSearchTerm, data]);

  useEffect(() => {
    const fetchSimulazioni = async () => {
      try {
        const response2 = await listSimulation();
        setSimulationList(response2);
      } catch (error) {
        console.error('Errore nel recupero delle simulazioni:', error);
      }
    };
    fetchSimulazioni();
  }, []);

  const changeSort = (key: keyof MyData) => {
    setSortConfig(currentSortConfig => {
      if (currentSortConfig.key === key) {
        return { key, direction: currentSortConfig.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: 'asc' };
    });
  };

  const exportDataToCSV = () => {
    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Data.csv');
  };

  const handleSimulationChange = (selectedOption: SelectedOption | null) => {
    if (selectedOption) {
      setTypeSimulation(selectedOption.value);
    } else {
      // Gestisci il caso in cui non vi sia alcuna selezione
      // Ad esempio, potresti voler resettare il filtro o gestire questo caso in altro modo
      setTypeSimulation(''); // O un altro valore di default appropriato
    }
  };
  
  return (
    <div className={`card ${className}`}>
      {/* begin::Barra ricerca */}
      <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent setParentSearchTerm={setSearchTerm} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Total Goggles */}
        <div className='me-10'>
          <span className='fw-bold'></span>
        </div>
        {/* end::Total Goggles */}
        {/* begin::Export */}
        <button type='button' className='btn btn-light-primary me-3' onClick={exportDataToCSV}>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Export
        </button>
        {/* end::Export */}
      </div>
      {/* end::Card toolbar */}
      </div>
      {/* end::Barra ricerca */}
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Storico risultati</span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        <div className='card-toolbar'>
          <Select
              className='form-select-solid form-select-lg fw-bold select-z-index'
              options={[
                { value: '', label: 'Tutte le simulazioni' },
                ...simulationList.map((simulazione) => ({ value: simulazione.Titolo, label: simulazione.Titolo }))
              ]}
              isSearchable={false}
              onChange={handleSimulationChange}
              placeholder= "Tutte le simulazioni"
          />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Nome_discente')}>Nome</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Cognome_discente')}>Cognome</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Simulazione')}>Simulazione</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Livello')}>Livello</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Punteggio')}>Punteggio</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('Data_risultato')}>Data</th>
                    <th className='p-0 min-w-150px th-padding text-dark fw-bold' onClick={() => changeSort('ID_Sessione_VR')}>Sessione VR</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {sortedData.slice(start, end).map(item => (
                    <tr key={item.ID_Risultato_livello}>
                      <td></td>
                      <td className='text-muted fw-semibold'>{item.Nome_discente === null ? "-" : item.Nome_discente}</td> 
                      <td className='text-muted fw-semibold'>{item.Cognome_discente === null ? "-" : item.Cognome_discente}</td>
                      <td className='text-muted fw-semibold'>{item.Simulazione === null ? "-" : item.Simulazione}</td> 
                      <td className='text-muted fw-semibold'>{item.Livello === null ? "-" : item.Livello}</td>
                      <td className='text-muted fw-semibold'>{item.Punteggio === null ? "-" : item.Punteggio}</td>
                      <td className='text-muted fw-semibold'>{item.Data_risultato === "Invalid Date" ? "-" : item.Data_risultato}</td>
                      <td className='text-muted fw-semibold'>{item.ID_Sessione_VR === null ? "-" : item.ID_Sessione_VR}</td> 
                      <td className='text-muted fw-semibold'>
                        <div className='btn-group'>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen052.svg'
                              className='svg-icon-2'
                            />
                          </button>
                          <ul className='dropdown-menu custom-dropdown-menu'>
                            {/*<li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('view-details', item.ID_Risultato_livello)}>
                                Dettagli risultato
                              </a>
                            </li>*/}
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('delete-levelresult', item.ID_Risultato_livello)}>
                                Elimina risultato
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
            <div className='pagination-z-index'>
              <UsersListPagination
                totalItems={filteredData.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
          </div>
          {/* end::Tap pane */}

        </div>
      </div>
      {/* end::Body */}
      {selectedRecordId !== null && (
        <>
        <ViewDetailsLevelResult show={showModal} handleClose={handleCloseModal} recordId={selectedRecordId}/>
        <DeleteLevelResult show={showDeleteModal} handleClose={handleDeleteCloseModal} recordId={selectedRecordId}/>
        </>
      )}
    </div>
  )
}

export {StoricoRisultatiWidget}